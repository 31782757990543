<template>
    <div>
        <v-dialog v-model="isUnauthorizedFlag" persistent max-width="500">
            <v-card>
                <v-card-title>
                        <p class="ma-0 body-heading font-weight-semi-bold">{{ $t("Warning_Message.Error")}}</p>
                </v-card-title>

                <v-divider></v-divider>

                <div class="pa-4">
                    <!-- Unauthorized Message -->
                    <p class="ma-0 body-text font-weight-regular">{{ $t("Customer.productComponent.Sorry_you_are_not_authorized_to_perform_this_action_Please_login_to_continue") }} </p>
                </div>

                <v-card-actions class="ma-0 pa-0">
                    <div class="px-4 pb-4 full-width-button d-flex justify-center align-center">
                        <button class="mt-2 px-4 py-2 rounded light-blue-background edit-address-action-button" @click="unauthorizedPopupClose()"><span class="px-2 py-2 body-text white-color-text font-weight-semi-bold">{{ $t("Warning_Message.Ok")}}</span></button>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <div class="pa-2 pa-sm-4 rounded-lg light-grey-border white-background product-component">
            <div class="d-flex flex-column justify-space-between">
                <img class="rounded product-image" :src="product_data.product_icon_image" alt="Product Image">

                <div class="pt-2 product-info-container">
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p class="ma-0 body-heading font-weight-semi-bold line-clamp product-name"
                                    v-bind="attrs"
                                    v-on="on"
                                    align="left"
                                >{{ product_data.product_name }}</p>
                            </template>
                            <span>{{ product_data.product_name }}</span>
                        </v-tooltip>
                    </div>
                    

                    <div class="pt-2 d-flex flex-column justify-center bottom-div">
                        <!-- <p class="ma-0 pb-2 body-heading font-weight-semi-bold light-blue-color-text" align="left">{{ product_data.list_price }} KS</p> -->
                        <button  class="pa-2 rounded light-blue-background button-class" @click="redirectToProductPage()">
                            <span class="body-heading font-weight-semi-bold white-color-text">{{ $t("Customer.productComponent.View_Product")}}</span>
                        </button>
                        </div>
                    <!--<div class="pt-2 d-flex flex-column justify-center bottom-div">
                        <p class="ma-0 pb-2 body-heading font-weight-semi-bold light-blue-color-text" align="left">{{ product_data.list_price }} KS</p>
                        <button v-if="!addedToCart" class="pa-2 rounded light-blue-background button-class" @click="addToCart('increment')">
                            <span class="body-heading font-weight-semi-bold white-color-text">Add to Cart</span>
                        </button>-->

                        <!-- Show Quantity Component -->
                       <!-- <div v-if="addedToCart">
                            <div class="py-1 d-flex justify-center align-center">
                                <img src="https://d3vsnl8idgwrlw.cloudfront.net/decrementIcon.svg" alt="decrement-icon" @click="addToCart('decrement')">
                                
                                <div v-if="!addToCartLoader">
                                    <p v-if="true" class="ma-0 pl-2 body-heading font-weight-medium product-quantity-width" align="center">{{ productQuantity }}</p>                            </div>
                                <div v-else class="pl-2">
                                    <v-progress-circular size="20" indeterminate color="#48ACEF"></v-progress-circular>
                                </div>

                                <img class="pl-2" src="https://d3vsnl8idgwrlw.cloudfront.net/incrementIcon.svg" alt="increment-icon" @click="addToCart('increment')">
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../utils/axios_utils';

export default {
    name: 'productHomeComponent',
    props: {
        product_data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            addedToCart: false,
            addToCartLoader: false,
            productQuantity: null,
            isUnauthorizedFlag: false,
        }
    },
    mounted() {

    },
    methods: {
        updateCart(operation) {
            this.addToCartLoader = true;
            if (operation == 'increment') {
                if (this.productQuantity == null) {
                    // Call addToCart API.
                    this.productQuantity++;
                    this.addToCart();
                } else if (this.productQuantity > 0) {
                    // Call addToCart API.
                    this.productQuantity++;
                    this.addToCart();
                } else if (this.productQuantity == 0) {
                    this.productQuantity = null;
                    // Call removeFromCart API.
                    this.removeFromCart();
                }
            } else {
                if (this.productQuantity == null) {
                    // Do Nothing.
                    this.addedToCart = true;
                } else if (this.productQuantity == 1) {
                    // Call removeFromCart API.
                    this.removeFromCart();

                    this.productQuantity = null;
                } else if (this.productQuantity > 1) {
                    // Call addToCart API.
                    this.productQuantity--;
                    this.addToCart();
                }
            }
        },
        addToCart() {
            let addToCartRequest = {
                productId: this.product_data.product_id,
                productQty: this.productQuantity,
            };

            axios_auth_instance_epharmacy_customer.post('/cart', addToCartRequest).then((addToCartResponse) => {
                console.log('addToCartResponse: ', addToCartResponse);
                this.addToCartLoader = false;
                this.addedToCart = true;
            }).catch((addToCartError) => {
                console.log('Error adding to cart: ', addToCartError);

                // Unauthorized - 401
                if (addToCartError.response.status == 401) {
                    this.isUnauthorizedFlag = true;
                }
            });
        },
        removeFromCart() {
            this.addToCartLoader = true;
            // Remove From Cart API.
            axios_auth_instance_epharmacy_customer.delete('/cart/'+ this.product_data.product_id).then((removeFromCartResponse) => {
                
                this.$emit('handleRemoveProduct', this.product_data.product_id);
                this.addToCartLoader = false;
                this.addedToCart = false;
            }).catch((removeFromCartError) => {
                console.log('Error adding to cart: ', removeFromCartError);
            });
        },
        unauthorizedPopupClose() {
            this.isUnauthorizedFlag = false;
        },
        redirectToProductPage() {
            this.$router.push({
                name: 'ProductDetailsPage',
                params: {
                    product_id: this.product_data.product_id
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';
.product-component {
    height: 100%;

    @media (max-width: 600px) {
        width: 138px !important;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 218px !important;
        border-radius: 8px;
    }

    @media (min-width: 1265px) {
        width: 218px !important;
        border-radius: 8px;
    }
}

.products-segment .product-container .product-component {
    height: 100%;

    @media (max-width: 360px) {
        width: 138px !important;
    }

    @media (min-width: 361px) and (max-width: 500px) {
        width: 160px !important;
    }

    @media (min-width: 501px) and (max-width: 600px) {
        width: 160px !important;
        border-radius: 8px;
    }

    @media (min-width: 601px) and (max-width: 920px) {
        width: 180px !important;
        border-radius: 8px;
    }

    @media (min-width: 921px) and (max-width: 1264px) {
        width: 160px !important;
        border-radius: 8px;
    }

    @media (min-width: 1265px) {
        width: 180px !important;
        border-radius: 8px;
    }
}

.product-image {
    object-fit: fill;

    @media (max-width: 600px) {
        height: 120px;
        width: 120px !important;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        height: 180px;
        width: 180px;
    }

    @media (min-width: 1265px) {
        height: 180px;
        width: 180px;
    }
}

.products-segment .product-container .product-image {
    object-fit: fill;

    @media (max-width: 360px) {
        height: 120px;
        width: 120px !important;
    }

    @media (min-width: 361px) and (max-width: 520px) {
        height: 144px;
        width: 144px !important;
    }

    @media (min-width: 521px) and (max-width: 600px) {
        height: 140px;
        width: 140px !important;
    }

    @media (min-width: 601px) and (max-width: 920px) {
        height: 144px;
        width: 144px !important;
    }

    @media (min-width: 921px) and (max-width: 1264px) {
        height: 124px;
        width: 124px !important;
    }

    @media (min-width: 1265px) {
        height: 144px;
        width: 144px !important;
    }
}

.product-name {
    width: 100%;
    
    @media (max-width: 600px) {
        height: 46px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        height: 48px;
    }

    @media (min-width: 1265px) {
        height: 52px;
    }
}

.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-one-line {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product-quantity-width {
    width: 60px;
}
</style>

<style lang="scss">
@import '../../scss/global.scss';
@import '../../scss/classes.scss';

</style>